import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { HighlightModule } from 'ngx-highlightjs';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ThemeConstantService } from './services/theme-constant.service';
import { CodeBoxComponent } from './directives/code-box.component'
import { SearchPipe } from './pipes/search.pipe';
import { PrivacyPolicyComponent } from '../components/policies/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from '../components/policies/terms-of-service/terms-of-service.component';
import { JwtRefreshInterceptor } from '../helpers/jwt-refresh.interceptor';
import { JwtInterceptor } from '../helpers/jwt.interceptor';

@NgModule({
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientXsrfModule,
        NgZorroAntdModule,
        PerfectScrollbarModule,
        CodeBoxComponent,
        HighlightModule,
        SearchPipe,
        PrivacyPolicyComponent,
        TermsOfServiceComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        HighlightModule.forRoot({ theme: 'atom-one-light' }),
        NgZorroAntdModule,
        PerfectScrollbarModule
    ],
    declarations: [
        CodeBoxComponent,
        SearchPipe,
        PrivacyPolicyComponent,
        TermsOfServiceComponent
    ],
    providers: [
        ThemeConstantService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtRefreshInterceptor, multi: true },
    ]
})

export class SharedModule { }
