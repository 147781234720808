import { Component, Input, OnInit, } from '@angular/core';
import { ThemeConstantService } from '../../../shared/services/theme-constant.service';
import { FanContactsService } from 'src/app/services/fan-contacts.service';

@Component({
    selector: 'terms-of-service',
    templateUrl: 'terms-of-service.component.html'
})

export class TermsOfServiceComponent {

}