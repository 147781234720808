import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ShareDataService {

  private orgSource = new BehaviorSubject(null);
  organization = this.orgSource.asObservable();

  constructor() { }

  changeOrganization(message: string) {
    this.orgSource.next(message);
  }

  failedToLoad(message: string) {
    this.orgSource.error(message);
  }
}