<div class="header">
  <div class="logo logo-dark">
    <a href="">
      <img src="assets/images/logo/evolved-white-2.png" alt="Logo" style="width: 100%; height: 70px;">
      <img class="logo-fold" src="assets/images/logo/evolved-e-white-2.png" style="width: 100%; height: 70px;" alt="Logo">
    </a>
  </div>
  <div class="logo logo-white">
    <a href="">
      <img src="assets/images/logo/evolved-3.png" alt="Logo" style="width: 100%;">
      <img class="logo-fold" src="assets/images/logo/logo-fold-2.jpg" style="width: 100%;" alt="Logo">
    </a>
  </div>
  <div class="nav-wrap">
    <ul class="nav-left">
      <li class="desktop-toggle">
        <a (click)="toggleFold()">
          <i nz-icon [type]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
        </a>
      </li>
      <li class="mobile-toggle">
        <a (click)="toggleExpand()">
          <i nz-icon [type]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
        </a>
      </li>
    </ul>
    <ul class="nav-right">
      <li>
        <span class="p-l-10 pointer" nz-dropdown [nzDropdownMenu]="profile" [nzTrigger]="'click'"
          [nzPlacement]="'bottomRight'">
          <nz-avatar nzIcon="user" nzSize="small" style="background-color:#c0c0c0;font-size:16px;" ></nz-avatar>
          Welcome, {{ email }}
        </span>
        <i nz-icon nzType="caret-down" theme="outline"></i>
        <nz-dropdown-menu #profile="nzDropdownMenu">
          <ul nz-menu class="p-b-15 p-t-15">
            <li nz-menu-item>
              <a class="p-v-5 d-flex align-items-center justify-content-between" [routerLink]="['/dashboard/my-account']">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon type="user" theme="outline"></i>
                  <span class="m-l-10">My Account</span>
                </div>                
              </a>
            </li>
            <li nz-menu-item>
              <a class="p-v-5 d-flex align-items-center justify-content-between" (click)="logout()">
                <div>
                  <i class="opacity-04 font-size-16" nz-icon type="logout" theme="outline"></i>
                  <span class="m-l-10">Logout</span>
                </div>
                <i class="font-size-10" nz-icon type="right" theme="outline"></i>
              </a>
            </li>
          </ul>
        </nz-dropdown-menu>
      </li>
    </ul>
  </div>
</div>