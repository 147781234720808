import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from 'src/app/config';

@Injectable()
export class OrganizationService {
  constructor(private http: HttpClient, private config: Config) { }

  getList() {
    return this.http.get<any[]>(this.config.getBaseUrl() + `/api/Organizations`);
  }

  getEntityList(orgId: string) {
    return this.http.get<any[]>(this.config.getBaseUrl() + `/api/Organizations/${orgId}/Entities`);
  }

  update(entity: any) {
    return this.http.put(this.config.getBaseUrl() + `/api/Organizations`, entity);
  }

  get(id: string) {
    return this.http.get<any>(this.config.getBaseUrl() + `/api/Organizations/${id}`);
  }

  delete(id: string) {
    return this.http.delete(this.config.getBaseUrl() + `/api/Organizations/${id}`);
  }

  create(entity: any) {
    return this.http.post(this.config.getBaseUrl() + `/api/Organizations`, entity);
  }
}
