import { Injectable } from "@angular/core";
import { environment } from "../environments/environment";
import { ServerConfig } from "./models/ServerConfig";
import { ConfigService } from "./services/config.service";

@Injectable({providedIn:'root'})
export class Config {
    private configPromise: Promise<ServerConfig> = null;

    constructor(private configService: ConfigService) {
    }

    public getBaseUrl() {
        return environment.apiUrl;
    }

    public async getServerConfig(): Promise<ServerConfig> {
        if (this.configPromise == null) { 
            this.configPromise = this.configService.get();
        }

        return this.configPromise;
    }
}