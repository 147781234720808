import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SharedModule } from '../shared.module';

import { HeaderComponent } from "./header/header.component";
import { SearchComponent } from "./search/search.component";
import { QuickViewComponent } from './quick-view/quick-view.component';
import { SideNavComponent } from "./side-nav/side-nav.component";
import { FooterComponent } from "./footer/footer.component";

import { SideNavDirective } from "../directives/side-nav.directive";
import { ThemeConstantService } from '../services/theme-constant.service';

import { UserService } from '../../services/user.service';
import { AdministratorService } from 'src/app/services/administrator.service';
import { EntityService } from 'src/app/services/entity.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { CategoryService } from 'src/app/services/categories.service';
import { TopicService } from 'src/app/services/topics.service';
import { QuestionService } from 'src/app/services/questions.service';
import { AccessRightManagement } from 'src/app/helpers/access-rights';
import { EventCommon } from '../event-common';


@NgModule({
  exports: [
    CommonModule,
    HeaderComponent,
    SearchComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent
  ],
  imports: [
    RouterModule,
    CommonModule,
    SharedModule
  ],
  declarations: [
    HeaderComponent,
    SearchComponent,
    QuickViewComponent,
    SideNavComponent,
    SideNavDirective,
    FooterComponent
  ],
  providers: [
    ThemeConstantService,
    UserService,
    OrganizationService,
    AdministratorService,
    EntityService,
    CategoryService,
    TopicService,
    QuestionService,
    AccessRightManagement,
    EventCommon
  ]
})

export class TemplateModule { }
