import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Observable } from "rxjs";
import { distinctUntilChanged, filter, map, startWith } from "rxjs/operators";
import { IBreadcrumb } from "../../shared/interfaces/breadcrumb.type";
import { ThemeConstantService } from '../../shared/services/theme-constant.service';
import { CacheService } from 'src/app/services/cache.service';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
    selector: 'app-common-layout',
    templateUrl: './common-layout.component.html',
})

export class CommonLayoutComponent implements OnInit {

    breadcrumbs$: Observable<IBreadcrumb[]>;
    contentHeaderDisplay: string;
    isFolded: boolean;
    isSideNavDark: boolean;
    isExpand: boolean;
    selectedHeaderColor: string;

    cacheNotificationMsgId = null;
    cacheNotificationEvolvedId = null;

    constructor(
        private messageService: NzMessageService,
        private cacheService: CacheService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private themeService: ThemeConstantService
    ) {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(() => {
                let child = this.activatedRoute.firstChild;
                while (child) {
                    if (child.firstChild) {
                        child = child.firstChild;
                    } else if (child.snapshot.data && child.snapshot.data['headerDisplay']) {
                        return child.snapshot.data['headerDisplay'];
                    } else {
                        return null;
                    }
                }
                return null;
            })
        ).subscribe((data: any) => {
            this.contentHeaderDisplay = data;
        });
    }

    ngOnInit() {
        this.breadcrumbs$ = this.router.events.pipe(
            startWith(new NavigationEnd(0, '/', '/')),
            filter(event => event instanceof NavigationEnd), distinctUntilChanged(),
            map(data => this.buildBreadCrumb(this.activatedRoute.root))
        );
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);
        this.themeService.selectedHeaderColor.subscribe(color => this.selectedHeaderColor = color);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);

        //setInterval(() => {
        //    this.CheckCacheJobs();
        //}, 5000);
    }

    private buildBreadCrumb(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
        let label = '', path = '/', display = null;

        if (route.routeConfig) {
            if (route.routeConfig.data) {
                label = route.routeConfig.data['title'];
                path += route.routeConfig.path;
            }
        } else {
            label = 'Dashboard';
            path += 'dashboard';
        }

        const nextUrl = path && path !== '/dashboard' ? `${url}${path}` : url;
        const breadcrumb = <IBreadcrumb>{
            label: label, url: nextUrl
        };

        const newBreadcrumbs = label ? [...breadcrumbs, breadcrumb] : [...breadcrumbs];
        if (route.firstChild) {
            return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
        }
        return newBreadcrumbs;
    }

    async CheckCacheJobs() {

        try {
            let activejobs: any = await this.cacheService.getActiveJobs().toPromise();

            //console.log("ACTIVE CACHE JOBS", activejobs);
            let generalActiveJobs = activejobs.filter(j => j.type != 3); //EvolvedScorecard = 3
            let evolvedActiveJobs = activejobs.filter(j => j.type == 3) //EvolvedScorecard = 3

            //General jobs
            if (generalActiveJobs.length > 0 && this.cacheNotificationMsgId === null) {
               // this.cacheNotificationMsgId = this.messageService.loading("Updating scorecard cache...", { nzDuration: 0 }).messageId;
            }
            else if (generalActiveJobs.length === 0 && this.cacheNotificationMsgId !== null) {
                //this.messageService.remove(this.cacheNotificationMsgId);
               // this.cacheNotificationMsgId = null;
            }

            //Evolved jobs
            if (evolvedActiveJobs.length > 0 && this.cacheNotificationEvolvedId === null) {
                //this.cacheNotificationEvolvedId = this.messageService.loading("Updating Evolved Dashboard cache...", { nzDuration: 0 }).messageId;
            }
            else if (evolvedActiveJobs.length === 0 && this.cacheNotificationEvolvedId !== null) {
                //this.messageService.remove(this.cacheNotificationEvolvedId);
               // this.cacheNotificationEvolvedId = null;
            }

        } catch (error) {
            console.error("Unable to get cache jobs.", error);
            //this.messageService.error("Unable to get cache jobs", { nzDuration: 5000 });
        }
    }
}

