import { Component, OnInit, Inject } from '@angular/core';
import { AppInsightService } from './services/appinsights.service';
import { DOCUMENT } from '@angular/common';
import { Config } from './config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    constructor(
        @Inject(DOCUMENT) 
        private doc: any,
        private appInsightService: AppInsightService,
        private config: Config
    ) {  }

    ngOnInit(): void {
        this.setAppInsights().then(() => {
            this.appInsightService.logEvent('Message', 'Application Loaded.');
        });        
    }
    private async setAppInsights() {
        try {
            var serverConfig = await this.config.getServerConfig();

            const s = this.doc.createElement('script');
            s.type = 'text/javascript';
            s.innerHTML = 'var appInsights=window.appInsights||function(a){ function b(a){c[a]=function(){var b=arguments;c.queue.push(function(){c[a].apply(c,b)})}}var c={config:a},d=document,e=window;setTimeout(function(){var b=d.createElement("script");b.src=a.url||"https://az416426.vo.msecnd.net/scripts/a/ai.0.js",d.getElementsByTagName("script")[0].parentNode.appendChild(b)});try{c.cookie=d.cookie}catch(a){}c.queue=[];for(var f=["Event","Exception","Metric","PageView","Trace","Dependency"];f.length;)b("track"+f.pop());if(b("setAuthenticatedUserContext"),b("clearAuthenticatedUserContext"),b("startTrackEvent"),b("stopTrackEvent"),b("startTrackPage"),b("stopTrackPage"),b("flush"),!a.disableExceptionTracking){f="onerror",b("_"+f);var g=e[f];e[f]=function(a,b,d,e,h){var i=g&&g(a,b,d,e,h);return!0!==i&&c["_"+f](a,b,d,e,h),i}}return c }({ instrumentationKey:\'' + serverConfig.aiKey + '\' }); window.appInsights=appInsights,appInsights.queue&&0===appInsights.queue.length&&appInsights.trackPageView();';
            const head = this.doc.getElementsByTagName('head')[0];
            head.appendChild(s);
        }
        catch
        {
        }
    }
}
