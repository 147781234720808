import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// import { User } from '../../models/user';
import { Config } from 'src/app/config';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private config: Config) { }

  // Gets a list of all organizations for current user
  getOrganizationList() {
    return this.http.get<any[]>(this.config.getBaseUrl() + `/Api/User/organizations`);
  }

  // Gets a list of entities for current user in specified organization
  getEntityList(orgId: string) {
    return this.http.get<any[]>(this.config.getBaseUrl() + `/Api/User/entities`, {
      params: {
        'organizationId': orgId
      }
    });
  }
}