import { SideNavInterface } from '../../interfaces/side-nav.type';

export const ROUTES: SideNavInterface[] = [
  {
    path: '/dashboard/evolved',
    title: 'Evolved Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'bar-chart',
    rights: ["permissions.reports.evolveddashboard"],
    submenu: [],
    external: false
  },
  {
    path: '/dashboard/default',
    title: 'Dashboard',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'dashboard',
    rights: ["RunReports"],
    submenu: [],
    external: false
  },
  {
    path: '/dashboard/employee-recognition',
    title: 'Employee Recognition',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'team',
    rights: ["RunReports"],
    submenu: [],
    external: false
  },
  {
    path: 'ADMIN_SITE',
    title: 'Administration',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    icon: 'appstore',
    rights: ['permissions.anyadmin'],
    submenu: [],
    external: true
  }
]