import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";
import { ServerConfig } from '../models/ServerConfig';

@Injectable()
export class ConfigService {
  constructor(private http: HttpClient) { }

  async get(): Promise<ServerConfig> {
      return this.http.get<ServerConfig>(environment.apiUrl+`/api/Config`).toPromise();
  }
}
