import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from 'src/app/config';

@Injectable()
export class CacheService {
    constructor(private http: HttpClient, private config: Config) { }

    getActiveJobs() {
        return this.http.get(this.config.getBaseUrl() + `/api/Cache/ActiveJobs`);
    }
}
