import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgZorroAntdModule, NZ_I18N, en_US } from 'ng-zorro-antd';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { TemplateModule } from './shared/template/template.module';
import { SharedModule } from './shared/shared.module';

import { AppComponent } from './app.component';
import { CommonLayoutComponent } from './layouts/common-layout/common-layout.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';

import { ToastrModule } from 'ngx-toastr';

import { NgChartjsModule } from 'ng-chartjs';
import { ThemeConstantService } from './shared/services/theme-constant.service';

import { Config } from './config';

import { ShareDataService } from './helpers/share.data.service';
import { CacheService } from './services/cache.service';

import { AppInsightService } from './services/appinsights.service';
import { ConfigService } from './services/config.service';
import { StoreService } from 'ng-chartjs/lib/store.service';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    CommonLayoutComponent,
    FullLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgZorroAntdModule,
    AppRoutingModule,
    TemplateModule,
    ToastrModule.forRoot(),
    SharedModule,
    NgChartjsModule,
    SharedModule
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: en_US,
    },
    ThemeConstantService,
    ConfigService,
    Config,
    ShareDataService,
    CacheService,
    AppInsightService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
