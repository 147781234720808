import { Injectable } from '@angular/core';
import { AppInsights, Microsoft } from 'applicationinsights-js';
import { environment } from '../../environments/environment';
import { ActivatedRouteSnapshot, ResolveEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Config } from '../config';


@Injectable()
export class AppInsightService {
    private routerSubscription: Subscription;

    private config: Microsoft.ApplicationInsights.IConfig = {
        instrumentationKey: null
    };
    constructor(private router: Router, private appConfig:Config) {
        appConfig.getServerConfig().then(serverConfig => {
            this.config.instrumentationKey = serverConfig.aiKey;

            if (!AppInsights.config) {
                AppInsights.downloadAndSetup(this.config);
                // If you want to log UserId in metrices
                // Logic to get logged in User
                //var user = User.GetUser();
                //AppInsights.setAuthenticatedUserContext(user);
            }
            this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
                const activatedComponent = this.getActivatedComponent(event.state.root);
                if (activatedComponent) {
                    this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
                }
            });
        });        

    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }

    logPageView(
        name?: string,
        url?: string,
        properties?: any,
        measurements?: any,
        duration?: number
    ) {
        AppInsights.trackPageView(name, url, properties, measurements, duration);
    }
    logEvent(name: string, properties?: any, measurements?: any) {
        AppInsights.trackEvent(name, properties, measurements);
    }
    logException(
        exception: Error,
        handledAt?: string,
        properties?: any,
        measurements?: any
    ) {
        AppInsights.trackException(exception, handledAt, properties, measurements);
    }
    logTrace(message: string, properties?: any, severityLevel?: any) {
        AppInsights.trackTrace(message, properties, severityLevel);
    }
}