import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from 'src/app/config';

@Injectable()
export class TopicService {
  constructor(private http: HttpClient, private config: Config) { }

  getList() {
      return this.http.get<any[]>(this.config.getBaseUrl()+`/api/Topics`);
  }

  getQuestionList(id: string) {
      return this.http.get<any[]>(this.config.getBaseUrl()+`/api/Topics/${id}/Questions`);
  }

  update(entityId: string, entity: any) {
      return this.http.put(this.config.getBaseUrl()+`/api/Topics/${entityId}`, entity);
  }

  get(id: string) {
    return this.http.get(this.config.getBaseUrl()+`/api/Topics/${id}`);
  }

  delete(id: string) {
    return this.http.delete(this.config.getBaseUrl()+`/api/Topics/${id}`);
  }

  create(entity: any) {
    return this.http.post(this.config.getBaseUrl()+`/api/Topics`, entity);
  }
}
