import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import * as jwt_decode from 'jwt-decode';
import { ShareDataService } from '../../../helpers/share.data.service';
import { UserService } from '../../../services/user.service';
import { AuthenticationService } from '../../../services/auth/authentication.service';
import { NzMessageService } from 'ng-zorro-antd';
import { AccessRightManagement } from 'src/app/helpers/access-rights';
import { OrganizationService } from 'src/app/services/organization.service';
import { SideNavInterface } from '../../interfaces/side-nav.type';
import { ServerConfig } from 'src/app/models/ServerConfig';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html'
})

export class SideNavComponent {

  public menuItems: SideNavInterface[] = [];
  isFolded: boolean;
  isSideNavDark: boolean;
  organizations: any[];
  organization: any = null;

  showOrganizations: boolean = false;
  showEmployeeRecognition: boolean = false;
  serverConfig: ServerConfig;

  constructor(
    private accessChecker: AccessRightManagement,
    private themeService: ThemeConstantService,
    private shareDataService: ShareDataService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private message: NzMessageService,
    private orgService: OrganizationService,
    config: Config
  ) {

    config.getServerConfig().then(sc => this.serverConfig = sc);
    this.showOrganizations = this.accessChecker.showOrgDropdown();
  }

  ngOnInit(): void {


    this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
    this.themeService.isSideNavDarkChanges.subscribe(isDark => this.isSideNavDark = isDark);

    this.userService.getOrganizationList().subscribe(orgList => {
      if (orgList.length < 1) {
        this.message.error('This account does not have an organization assigned to it');
        return;
      }

      this.organizations = orgList;

      var savedOrganizationId:string = this.getSavedOrganization();
      var org = orgList.find(x => x.id == savedOrganizationId) || orgList[0];

      this.setOrganization(org.id);
    }, error => {
            this.shareDataService.failedToLoad(error);
    });

  }

  setMenuOptions() {
    this.menuItems = ROUTES.filter(menuItem => {
      let hasAccess = menuItem.rights.some(r => this.accessChecker.hasRight(r));

      if (menuItem.path == '/dashboard/employee-recognition') {
        return hasAccess && this.showEmployeeRecognition;
      }

      return hasAccess;
    });
  }

  // Sets the organization throughout the site
  // The scorecard pages are subscribed to this
  setOrganization(organizationId) {
    if (typeof organizationId === 'string') {
      this.orgService.getEntityList(organizationId).subscribe(entities => {
        this.showEmployeeRecognition = entities.some(e => e.enableEmployeeRecognition);
        this.setMenuOptions();
      });
    }

    this.shareDataService.changeOrganization(organizationId);
    this.organization = organizationId;
    this.saveOrganization(organizationId);
    
  }

  saveOrganization(orgId: string) {
    let d:Date = new Date();
    d.setTime(d.getTime() + 14 * 24 * 60 * 60 * 1000);
    let expires:string = `expires=${d.toUTCString()}`;

    document.cookie = `orgId=${orgId}; ${expires}`;
  }

  getSavedOrganization() : string {
    var cookies: Array<string> = document.cookie.split(';');
    var cookieName = 'orgId=';
    var c: string;

    for (let i: number = 0; i < cookies.length; i += 1) {
      c = cookies[i].replace(/^\s+/g, '');
        if (c.indexOf(cookieName) == 0) {
            return c.substring(cookieName.length, c.length);
        }
    }
    return null;
  }
}
