import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { throwError } from "rxjs";
import { BehaviorSubject } from "rxjs/BehaviorSubject";
import { catchError, switchMap, filter, take } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { NzMessageService } from 'ng-zorro-antd';

@Injectable()
export class JwtRefreshInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    // Refresh Token Subject tracks the current token, or is null if no token is currently
    // available (e.g. refresh pending).
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );
    constructor(private authenticationService: AuthenticationService, private toastr: ToastrService, private message: NzMessageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {

            
            // We don't want to refresh token for some requests like login or refresh token itself
            // So we verify url and we throw an error if it's the case
            if (request.url.includes("/Api/Auth/RefreshAsync") ||
                request.url.includes("/Api/Auth/Login")) {

                // We do another check to see if refresh token failed
                // In this case we want to logout user and to redirect it to login page

                if (request.url.includes("/Api/Auth/RefreshAsync")) {
                    this.authenticationService.logout();
                    location.reload(true);
                }

                return this.showError(error);
            }

            // If error status is different than 401 we want to skip refresh token
            // So we check that and throw the error if it's the case
            if (error.status !== 401) {
                return this.showError(error);
            }

            

            if (this.refreshTokenInProgress) {
                // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                // – which means the new token is ready and we can retry the request again
                return this.refreshTokenSubject
                    .pipe(filter(result => result !== null))
                    .pipe(take(1))
                    .pipe(switchMap(() => next.handle(this.addAuthenticationToken(request))));
            } else {
                this.refreshTokenInProgress = true;

                // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                this.refreshTokenSubject.next(null);
                
                var tokens = this.authenticationService.getTokens();

                // Call auth.refreshAccessToken(this is an Observable that will be returned)
                return this.authenticationService
                    .refreshToken(tokens)
                    .pipe(switchMap((token: any) => {
                        //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                        // for the next time the token needs to be refreshed
                        localStorage.setItem('currentUser', JSON.stringify(token));

                        this.refreshTokenInProgress = false;
                        this.refreshTokenSubject.next(token);

                        return next.handle(this.addAuthenticationToken(request));
                    })).pipe(catchError(error => {
                        this.refreshTokenInProgress = false;

                        this.authenticationService.logout();
                        location.reload(true);
                        return this.showError(error);
                    }));
            }
            
        }));
    }

    showError(err): Observable<HttpEvent<any>> {
            
            var error:string = null;

            if (err.error) {

                if(err.error.Message) {
                    this.toastr.error(err.error.Message);
                    error = err.error.Message;
                }

                if(err.error.message) {
                    this.toastr.error(err.error.message);
                    error = err.error.message;
                }

                if (err.error.errors) {
                    err.error.errors.forEach(error => {
                        this.toastr.error(error.description);
                    });
                }

                if (err.error.error) {
                    this.toastr.error(err.error.error);
                    error = err.error.error;
                }
            }            

            if (!error) {
                error = err.statusText;
                this.toastr.error(error);
            }

            return throwError(error);
    }

    addAuthenticationToken(request) {

        var tokens = this.authenticationService.getTokens();

        if (tokens) {
            return request.clone({
                setHeaders: {
                Authorization: `Bearer ${tokens.token}`
                }
            });
        }

        return request;
    }
}