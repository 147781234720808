import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Config } from 'src/app/config';
import { AccessRightManagement } from 'src/app/helpers/access-rights';
import { ServerConfig } from 'src/app/models/ServerConfig';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {


  constructor(
    private router: Router,
    private accessChecker: AccessRightManagement,
    private config: Config
  ) { 
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser')) {
      //logged in

      if (!this.accessChecker.hasRight("RunReports")) {
        //no access

        if (this.accessChecker.hasRight("permissions.efficiencyaudit.access")) {
          //user can access audit, so redirect
          this.config.getServerConfig().then(cfg => {
            window.location.href = cfg.efficiencyAuditSiteUrl;
          });
          return false;
        }

        //handle lower
        return true;
        
      }

      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
