<div class="row">
  <div class="col-md-12">
    <nz-card nzTitle="Terms of Service">
      <p><strong>Effective Date: September 1, 2019 </strong></p>
      <h2>Terms and Conditions for Evolved Experience Solutions, Inc. </h2>
      <h4>Introduction</h4>
      <p>This website is operated by Evolved Experience Solutions, Inc., a California corporation (“Evolved”). These
        Website Standard Terms and Conditions (“Terms”) govern your access to and use of our website, <a
          href="http://www.EvolvedExperienceSolutions.com"
          target="_blank">www.<wbr>EvolvedExperienceSolutions.com</a><wbr>, (“Website”), and any websites, mobile applications, or other online services operated by Evolved where these Terms are posted. </p>
          <p>By using this Website, you are agreeing to these Terms. If you don’t agree with the Terms, then you may
            not use the Website or access any content or material made available through the Website. </p>
          <h4>Intellectual Property Rights </h4>
          <p>All information, content, and services displayed on, transmitted through, or contained in this Website
            (with the exception of User Content defined below) is owned by Evolved and/or its licensors or suppliers.
            Evolved reserves all of its intellectual property rights in the Website. Using the Website does not give
            you any ownership in our Website or the content or information made available through our Website. You are
            granted a limited license only for purposes of viewing the material contained on this Website. </p>
          <h4>Restrictions</h4>
          <p>You are specifically restricted from all of the following: </p>
          <ul style="padding-left: 30px;">
            <li>publishing any Website material in any other media; </li>
            <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
            <li>publicly performing and/or showing any Website material;</li>
            <li>using this Website in any way that is or may be damaging to this Website;</li>
            <li>using this Website in any way that impacts user access to this Website;</li>
            <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the
              Website, or to any person or business entity;</li>
            <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in
              relation
              to this Website; </li>
            <li>using this Website to engage in any advertising or marketing.</li>
          </ul>
          <p>Evolved has restricted your access to certain areas of this Website and may further restrict access by
            you to any areas of this Website, at any time, in its absolute discretion. </p>
          <h4>User Content </h4>
          <p>“User Content” shall mean any material, including but not limited to, content, communications, audio
            files, video clips, text, images or other audio-visual content you post, display, upload or otherwise make
            available on this Website. By posting, displaying, uploading, or otherwise making available your User
            Content, you grant Evolved a non-exclusive, worldwide, irrevocable, royalty-free, perpetual right and
            fully sub-licensable license to use, reproduce, adapt, publish, translate and distribute such User
            Content, in whole or in part, in any form, format, or medium. </p>
          <p>By posting, displaying, uploading or otherwise making available your User Content, you represent and
            warrant your User Content is your own, does not invade any third-party&#39;s rights, and will not cause
            injury to any person or entity. </p>
          <p>Evolved reserves the right to remove any User Content from this Website at any time without notice. </p>
          <h4>No warranties</h4>
          <p>To the extent permitted by law, this Website is provided on an “as is” and “as available” basis, with all
            faults, and Evolved disclaims all representations and warranties of any kind related to this Website and
            the materials contained on this Website. </p>
          <h4>Limitation of liability</h4>
          <p>In no event shall Evolved, nor any of its officers, directors and employees, be held liable for any
            damages, including without limitation any indirect, consequential, special, incidental, or punitive
            damages, arising out of or in any way connected with these Terms or your use of this Website, regardless
            of whether the damages arise from (a) breach of contract, (b) breach of warranty, (c) negligence, or (d)
            any other cause of action, to the extent such exclusion and limitations are not prohibited by applicable
            law. . </p>
          <h4>Indemnification</h4>
          <p>To the fullest extent permitted by law, you agree to indemnify and hold harmless Evolved, its
            subsidiaries, affiliates, shareholders, officers, directors, agents, employees and representatives, from
            and against any and all liabilities, costs, demands, causes of action, damages and expenses, including
            attorney’s fees, arising from or in any way related to your use of our Websites and/or your breach of any
            of the provisions of these Terms. </p>
          <h4>Severability</h4>
          <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be
            deleted without affecting the remaining provisions herein. </p>
          <h4>Variation of Terms</h4>
          <p>Evolved may revise these Terms at any time. You can review the most current version of the Terms on this
            page at any time. Your continued use of or access to our Website following the posting of any changes to
            these Terms constitutes acceptance of those changes. </p>
          <h4>Assignment</h4>
          <p>Evolved may freely assign, transfer, and subcontract its rights and/or obligations under these Terms
            without any notice to you. However, you may not assign or delegate any rights or obligations under these
            Terms. Any purported assignment and/or delegation by you shall be ineffective. </p>
          <h4>Entire Agreement</h4>
          <p>These Terms constitute the entire agreement between Evolved and you in relation to your use of this
            Website and supersede all prior agreements and understandings regarding such subject matter. </p>
          <h4>Governing Law &amp; Jurisdiction</h4>
          <p>These Terms will be governed by and interpreted in accordance with the laws of the State of California.
            You and Evolved both agree to submit to the jurisdiction of the state and federal courts located in the
            County of Fresno, State of California, United States of America, for the resolution of any disputes. </p>
    </nz-card>
  </div>
</div>
