import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from 'src/app/config';

@Injectable()
export class EntityService {
  constructor(private http: HttpClient, private config: Config) { }

  getIntervalList(id: string) {
    return this.http.get<any>(this.config.getBaseUrl() + `/Api/Entities/${id}/Intervals`);
  }

}
