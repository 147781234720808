<perfect-scrollbar class="side-nav" sideNav>
  <div class="m-v-25" *ngIf='showOrganizations'>
    <label class="p-h-15 w-100" style="font-weight: bold; display: block; margin-bottom: 5px;"> 
      {{isFolded ? "Org..." : "Organization"}}
    </label>
    <nz-select class="p-h-15 w-100" [(ngModel)]="organization" [nzDropdownMatchSelectWidth]="false" nzPlaceHolder="Organization"
      (ngModelChange)="setOrganization(organization)">
      <nz-option *ngFor="let org of organizations" nzValue={{org.id}} nzLabel={{org.name}}></nz-option>
    </nz-select>
  </div>
  <ul class="ant-menu ant-menu-root ant-menu-inline side-nav-menu" [ngClass]="{
            'ant-menu-inline-collapsed': isFolded,
            'ant-menu-dark' : isSideNavDark
        }">
    
    <ng-container *ngFor="let item of menuItems">
      <li *ngIf="item.external" class="ant-menu-item">
        <a [href]="item.path.replace('ADMIN_SITE', serverConfig.adminSiteUrl)" target="_blank" class="ant-menu-title">
          <i *ngIf="item.iconType == 'nzIcon'" nz-icon [type]="item.icon" [theme]="item.iconTheme"></i>
          <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
          <span>{{item.title}}</span>
        </a>
      </li>
      <li  [ngClass]="item.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
        *ngIf="!item.external"
        [routerLinkActive]="item.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
        [routerLinkActiveOptions]="{exact: true}">
        <a href="javascript:void(0);" class="ant-menu-submenu-title" *ngIf="item.submenu.length > 0">
          <i *ngIf="item.iconType == 'nzIcon'" nz-icon [type]="item.icon" [theme]="item.iconTheme"></i>
          <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
          <span>{{item.title}}</span>
          <i class="ant-menu-submenu-arrow"></i>
        </a>
        <a [routerLink]="item.path" *ngIf="!item.submenu.length > 0">
          <i *ngIf="item.iconType == 'nzIcon'" nz-icon [type]="item.icon" [theme]="item.iconTheme"></i>
          <i *ngIf="item.iconType == 'fontawesome'" class="m-r-10" [ngClass]="[item.iconTheme , item.icon]"></i>
          <span>{{item.title}}</span>
        </a>
  
        <!-- Second Level -->
        <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="item.submenu.length > 0">
          <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
            *ngFor="let subItem of item.submenu"
            [routerLinkActive]="subItem.submenu.length > 0 ? 'ant-menu-submenu-open' : 'ant-menu-item-selected'"
            [routerLinkActiveOptions]="{exact: true}">
            <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">
              <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [type]="subItem.icon" [theme]="subItem.iconTheme"></i>
              <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
              <span>{{subItem.title}}</span>
              <i class="ant-menu-submenu-arrow"></i>
            </a>
            <a [routerLink]="subItem.path" class="p-l-30" *ngIf="!subItem.submenu.length > 0">
              <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [type]="subItem.icon" [theme]="subItem.iconTheme"></i>
              <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
              <span>{{subItem.title}}</span>
            </a>
  
            <!-- Third Level -->
            <ul class="ant-menu ant-menu-inline ant-menu-sub dropdown-menu" *ngIf="subItem.submenu.length > 0">
              <li [ngClass]="subItem.submenu.length > 0?'ant-menu-submenu ant-menu-submenu-inline':'ant-menu-item'"
                *ngFor="let subItem of subItem.submenu" routerLinkActive="ant-menu-item-selected"
                [routerLinkActiveOptions]="{exact: true}">
                <a href="javascript:void(0);" class="ant-menu-submenu-title p-l-50" *ngIf="subItem.submenu.length > 0">
                  <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [type]="subItem.icon" [theme]="subItem.iconTheme"></i>
                  <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                    [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                  <span>{{subItem.title}}</span>
                  <i class="ant-menu-submenu-arrow"></i>
                </a>
                <a [routerLink]="subItem.path" class="p-l-50" *ngIf="!subItem.submenu.length > 0">
                  <i *ngIf="subItem.iconType == 'nzIcon'" nz-icon [type]="subItem.icon" [theme]="subItem.iconTheme"></i>
                  <i *ngIf="subItem.iconType == 'fontawesome'" class="m-r-10"
                    [ngClass]="[subItem.iconTheme , subItem.icon]"></i>
                  <span>{{subItem.title}}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ng-container>
    
  </ul>
</perfect-scrollbar>
