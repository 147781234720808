import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

// import * as jwt_decode from 'jwt-decode';
import { Config } from 'src/app/config';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  constructor(private http: HttpClient, private config: Config) { }

  login(username: string, password: string) {
    return this.http.post<any>(this.config.getBaseUrl() + `/Api/Auth/Login`, { username, password }, { withCredentials : true })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(user));
        }

        return user;
      }));
  }

  refreshToken(request: any) {
    return this.http.post(this.config.getBaseUrl() + "/Api/Auth/RefreshAsync", request);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
  }

  getUserDetails(): any {
    return this.http.get<any>(this.config.getBaseUrl() + '/Api/Auth/MyProfile');
  }

  updateProfile(user: any) {
    return this.http.post<any>(this.config.getBaseUrl() + `/Api/Auth/MyProfile`, user);
  }

  getTokens() {
    var data = localStorage.getItem('currentUser');

    if (data) {
      var currentUser = JSON.parse(data);

      if (currentUser.token) {
        return {
          token: currentUser.token,
          refreshToken: currentUser.refreshToken
        }
      }      
    }

    return null;    
  }

  sendPasswordResetEmail(username: string) {
    return this.http.post<any>(this.config.getBaseUrl() + '/Api/Auth/SendPasswordReset', {}, {
      params: {
        username: username
      }
    });
  }

  resetPassword(username: string, code: string, password: string, confirmPassword: string) {
    return this.http.post<any>(this.config.getBaseUrl() + `/Api/Auth/ResetPassword`, {
      username: username,
      code: encodeURIComponent(code),
      password: password,
      confirmPassword: confirmPassword
    });
  }

  checkRegistrationInfo(username: string, code: string) {
    return this.http.get<any>(this.config.getBaseUrl() + `/Api/Auth/RegistrationInfo`, {
      params: {
        username: username,
        code: encodeURIComponent(code)
      }
    });
  }

  register(username: string, code: string, password: string, confirmPassword: string) {
    return this.http.post<any>(this.config.getBaseUrl() + `/Api/Auth/Register`, {
      username: username,
      code: encodeURIComponent(code),
      password: password,
      confirmPassword: confirmPassword
    });
  }

  resendActivationEmail(username: string) {
    return this.http.post<any>(this.config.getBaseUrl() + '/Api/Auth/ResendActivation', {}, {
      params: {
        username: username
      }
    });
  }

  changePassword(password: string, newPassword: string, confirmPassword: string) {
    return this.http.post<any>(this.config.getBaseUrl() + `/Api/Auth/ChangePassword`, {
      currentPassword: password,
      newPassword: newPassword,
      confirmNewPassword: confirmPassword
    });
  }

  
}
