import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from 'src/app/config';

@Injectable()
export class QuestionService {
  constructor(private http: HttpClient, private config: Config) { }

  getList() {
      return this.http.get<any[]>(this.config.getBaseUrl()+`/api/Questions`);
  }

  getUnlinkedList() {
      return this.http.get<any[]>(this.config.getBaseUrl()+`/api/Questions/Unlinked`);
  }


  setTopic(id: string, topicId: string) {
      return this.http.get<any[]>(this.config.getBaseUrl()+`/api/Questions/SetTopic/${id}/${topicId}`);
  }

  removeTopic(id: string, topicId: string) {
      return this.http.get<any[]>(this.config.getBaseUrl()+`/api/Questions/RemoveTopic/${id}/${topicId}`);
  }

  
  update(entityId: string, entity: any) {
      return this.http.put(this.config.getBaseUrl()+`/api/Questions/${entityId}`, entity);
  }

  get(id: string) {
    return this.http.get(this.config.getBaseUrl()+`/api/Questions/${id}`);
  }

  delete(id: string) {
    return this.http.delete(this.config.getBaseUrl()+`/api/Questions/${id}`);
  }

  create(entity: any) {
    return this.http.post(this.config.getBaseUrl()+`/api/Questions`, entity);
  }
}
