import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Config } from 'src/app/config';

@Injectable()
export class AdministratorService {
    constructor(private http: HttpClient, private config: Config) { }

    create(entity: any) {
        return this.http.post(this.config.getBaseUrl() + `/Api/Admins`, entity);
    }

    get(id: string) {
        return this.http.get<any>(this.config.getBaseUrl() + `/Api/Admins/` + id);
    }

    all() {
        return this.http.get<any>(this.config.getBaseUrl() + `/Api/Admins/All`);
    }

    update(request: any) {
        return this.http.put<any>(this.config.getBaseUrl() + '/Api/Admins', request)
    }

    delete(userId: string) {
        return this.http.delete<any>(this.config.getBaseUrl() + `/Api/Admins/${userId}`, {})
    }
}
