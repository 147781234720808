import { Routes } from '@angular/router';
import { AuthGuard } from '../../services/auth/auth.guard';
import { DefaultDashboardComponent } from 'src/app/dashboard/main/default-dashboard.component';
import { PrivacyPolicyComponent } from 'src/app/components/policies/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from 'src/app/components/policies/terms-of-service/terms-of-service.component';


export const CommonLayout_ROUTES: Routes = [

  //Dashboard
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: {
      title: 'Privacy Policy',
      headerDisplay: "none"
    }
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent,
    data: {
      title: 'Terms of Service',
      headerDisplay: "none"
    }
  },
  //Apps
  {
    path: 'apps',
    data: {
      title: 'Apps'
    },
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      }
    ]
  }
];