import { Injectable } from "@angular/core";

@Injectable()
export class EventCommon {
    constructor() { }

    eventTypes = {
        "Baseball": { isSport: true, hasAttendance: true },
        "Basketball": { isSport: true, hasAttendance: true },
        "Concert": { isSport: false, hasAttendance: true },
        "Football": { isSport: true, hasAttendance: true },
        "Golf": { isSport: false, hasAttendance: true }, //isSport: false because we don't want to display opponent or score
        "Hockey": { isSport: true, hasAttendance: true },
        "Restaurant": { isSport: false },
        "Hotel": { isSport: false },
        "Soccer": { isSport: true, hasAttendance: true },
        "Tennis": { isSport: true, hasAttendance: true },
        "Other": { isSport: false },
        "Other-Event": { isSport: true, hasAttendance: true }
    };

    isSport(eventType : string) : boolean {
        return eventType 
            && this.eventTypes[eventType] 
            && this.eventTypes[eventType].isSport;
    }

    getEventTypeDetails(eventType : string) {
        return this.eventTypes[eventType] ? 
            this.eventTypes[eventType] :
            { }
    }

    getEventTypeNames() {
        return Object.keys(this.eventTypes);
    }
}