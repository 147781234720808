import * as jwt_decode from 'jwt-decode';

export class AccessRightManagement {
    private tokenLoaded: boolean = false;
    rights: any[] = [];
    //role: string;
    orgs: string[] = [];
    implicitOrgs: string[] = [];
    entities: string[] = [];

    constructor() {
        this.loadToken();        

    }

    private loadToken() {
        var jwt = localStorage.getItem('currentUser');
        if (!jwt) { return; }

        var jwtDecoded = jwt_decode(jwt);

        this.rights = [].concat(jwtDecoded['AccessRights'] || []);
        //this.role = jwtDecoded['role'];
        this.orgs = [].concat(jwtDecoded['Organization'] || []);
        this.implicitOrgs = [].concat(jwtDecoded['ImplicitOrganization'] || []);
        this.entities = [].concat(jwtDecoded['Entity'] || []);
        this.tokenLoaded = true;
    }

    hasRight(rightName: string): boolean {
        if (!this.tokenLoaded) 
        {
            this.loadToken();
            if (!this.tokenLoaded) {
                return false;
            }
        }
        return this.rights.includes(rightName) || this.rights.includes("permissions.all");
    }

    showOrgDropdown(): boolean {
        return this.hasRight("ViewAllClientPortals") || this.implicitOrgs.length > 1;
    }

    useOrgView(orgId: string): boolean {
        return this.hasRight("ViewAllClientPortals") || this.orgs.some(x => x == orgId);
    }
}