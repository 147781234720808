<div class="row">
  <div class="col-md-12">
    <nz-card nzTitle="Privacy Policy">
      <p><strong>Effective Date: September 1, 2019 </strong></p>
      <h2>Privacy Notice</h2>
      <h4>By using our websites and services, you hereby consent to these terms. </h4>
      <p>This privacy notice discloses the privacy practices for Evolved Experience Solutions, Inc. and affiliated
        website properties. This privacy notice applies solely to information collected by this website. It will notify
        you of the following: </p>
      <p>1. What personally identifiable information is collected from you through the website, how it </p>
      <p>is used and with whom it may be shared. 2. What choices are available to you regarding the use of your data. 3.
        The security procedures in place to protect the misuse of your information. 4. How you can correct any
        inaccuracies in the information. </p>
      <h4>Information Collection, Use, and Sharing </h4>
      <p>We collect personally identifiable information you provide to us through web forms, emails, or other direct
        contact from you. The information collected will depend on the context of your interactions with Enhanced
        Experience Solutions, Inc., and may include your first and last name, email address, employer name, employer
        address, postal address, phone number, and other similar contact data. We also may collect passwords, password
        hints, and similar security information used for authentication and account access for users who create an
        account. </p>
      <p>We will use your personally identifiable information to respond to your requests and inquiries and to provide
        contracted and/or agreed to business services. Unless you ask us not to, we may contact you via email in the
        future to tell you about specials, new products or services, or changes to this Privacy Notice. </p>
      <p>We employ other companies and individuals to perform functions on our behalf. Examples include [e.g., analyzing
        data, providing marketing assistance, processing credit card payments, providing web hosting, and providing
        customer service]. They have access to personal information needed to perform their functions but may not use it
        for other purposes. In the course of providing the services you have agreed to and/or contracted for, we may
        share your information with affiliated businesses or organizations we control, and which are also subject to
        this Privacy Notice. We will not share your personally identifiable information with any other third parties.
      </p>
      <h4>Your Access to and Control Over Information </h4>
      <p>You may opt out of any future contacts from us at any time. You can do the following at any time by contacting
        us via the email address or phone number given on our website: </p>
      <p>● See what data we have about you, if any. </p>
      <p>● Change/correct any data we have about you. </p>
      <p>● Have us delete any data we have about you. </p>
      <p>● Express any concern you have about our use of your data. </p>
      <h4>Security</h4>
      <p>We take precautions to protect your information. When you submit sensitive information via the website, your
        information is protected both online and offline. </p>
      <p>Wherever we collect sensitive information, that information is encrypted and transmitted to us in a secure way.
        You can verify this by looking for a lock icon in the address bar and looking for &quot;https&quot; at the
        beginning of the address of the Web page. </p>
      <p>While we use encryption to protect sensitive information transmitted online, we also protect your information
        offline. Only employees who need the information to perform a specific job (for example, billing or customer
        service) are granted access to personally identifiable information. </p>
      <h4>Website Analytics</h4>
      <p>We use cookies to collect information about user activity on our website. Our website uses Google Analytics, a
        service which transmits website traffic data to Google servers in the United States. Google Analytics does not
        identify individual users or associate your IP address with any other data held by Google. We use reports
        provided by Google Analytics to help us understand website traffic and webpage usage. You can learn more about
        how Google Analytics collects and processes data by visiting <a
          href="http://www.google.com/policies/privacy/partners"
          target="_blank">www.google.com/policies/<wbr>privacy/partners</a>. You can opt-out of tracking used to
          understand website utilization provided by Google Analytics by visiting <a
            href="https://tools.google.com/dlpage/gaoptout"
            target="_blank">https://tools.google.com/<wbr>dlpage/gaoptout</a>. </p>
      <h4>Do Not Track Signals</h4>
      <p>Some browsers have a “Do Not Track” feature that lets you tell certain websites you do not want your online
        activities tracked. Evolved Experience Solutions, Inc. does not respond to Do Not Track signals. We do not
        authorize the collection of your personally identifiable information over time across third party websites. </p>
      <h4>California Residents</h4>
      <p>If you are a California resident, you have the right to request the names and addresses of any and all third
        parties with whom we have shared your personal information for the third parties’ direct marketing purposes in
        the immediately preceding calendar year, and to receive a list of the categories of personal information we
        shared. (California Civil Code sections 1798.83– 1798.84.) If you are a California resident and would like to
        make such a request, please contact us. </p>
      <h4>Revisions to this Privacy Policy</h4>
      <p>We may revise this Privacy Notice from time to time. You will be notified of any changes on our Privacy Notice
        webpage. </p>
      <p><strong>If you feel that we are not abiding by this privacy policy, you should contact us immediately via our
          information form at <a href="http://www.evolvedexperiencesolutions.com"
            target="_blank">www.<wbr>evolvedexperiencesolutions.com</a> </strong></p>
    </nz-card>
  </div>
</div>
